//
//** Nav Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--portlets-base() {
    $space: array-get($m--portlet, layout, self, space);
    $height: 5.1rem;
    $height-sm: 4.1rem;
    $height-lg: 7rem;

    $body-padding: $space $space;
    $footer-padding: 1.1rem $space;
    $responsive-head-padding-y: 0.9rem;
    $bordered-semi-portlet-body-padding-top: 0.5rem;

    .m-portlet  {
        margin-bottom: $space;

        &.m-portlet--bottom-fit {
            margin-bottom: 0;
        }

        .m-portlet__head  {            
            display: flex; 
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            padding: 0;
            padding: 0 $space;
            height: $height;
            position:relative;

            .m-portlet__head-progress {
                position: absolute; 
                left: 0; 
                right: 0; 
            }

            .m-portlet__head-wrapper {
                display: flex; 
                flex-direction: row;
                align-items: stretch;
                justify-content: space-between; 
                flex-grow: 1;
            }

            .m-portlet__head-caption  {
                display: flex;
                align-items: center;
                align-content: flex-start;

                .m-portlet__head-title  {
                    display: flex;
                    align-items: center;

                    .m-portlet__head-icon  {
                        display: flex;
                        align-items: center;
                        line-height: 0;
                        padding: 0 13px 0 0;

                        @include icons-attr(font-size, (fontawesome: 1.4rem, lineawesome: 1.6rem, metronic: 1.8rem) );

                        i {
                            vertical-align: middle;
                            line-height: 0;
                            display: inline-block;  
                        }  
                    }

                    .m-portlet__head-text  {
                        display: flex;
                        align-items: center;
                        font-size: get-font-size(regular, '+++');
                        font-weight: get-font-weight(bolder);
                        font-family: #{get-heading-font-family()};
                        margin: 0;
                        padding: 0;

                        small {
                            display: inline-block;
                            padding-left: 5px;
                            font-size: get-font-size(regular);
                            font-weight: get-font-weight(normal);
                            font-family: #{get-heading-font-family()};
                        }

                        .m-portlet__head-desc {
                            margin-top: 0.4rem;
                            display: block;
                            font-size: get-font-size(regular, '-');
                            font-weight: get-font-weight(normal);
                            font-family: #{get-heading-font-family()};
                        } 
                    }                    
                }
            }

            .m-portlet__head-tools  {
                display: flex;
                align-items: center;
                align-content: flex-end;

                // buttons
                .btn {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                // portlet nac
                .m-portlet__nav {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    .m-portlet__nav-item {
                        display: flex;
                        padding: 8px 5px;
                        align-items: center;

                        &.m-portlet__nav-item--last {
                            padding-right: 0;
                        }

                        .m-portlet__nav-link {
                            &.m-portlet__nav-link--icon {
                                padding: 0 2px;
                                @include icons-attr(font-size, (fontawesome: 1.4rem, lineawesome: 1.4rem, metronic: 1.8rem) );

                                &.m-portlet__nav-link--icon-lg {
                                    @include icons-attr(font-size, (fontawesome: 1.6rem, lineawesome: 1.8rem, metronic: 2rem) );
                                }

                                &.m-portlet__nav-link--icon-xl {
                                    @include icons-attr(font-size, (fontawesome: 2rem, lineawesome: 2.2rem, metronic: 2.4rem) );
                                }

                                i {
                                    display: inline-block;  
                                }  
                            }  

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        &:first-child {
                            .m-portlet__nav-item {
                                padding-right: 0;
                            }
                        }
                    }
                }

                .nav.nav-tabs,
                .nav.nav-pills {
                    margin: 0;
                }

                .nav.nav-pills {
                    &.m-nav-pills--align-right {
                        float: right;
                    }
                }
            }   

            &.m-portlet__head--fit {
                position: relative;
                z-index: 1;
            }
        }

        .m-portlet__body  {
            //overflow: hidden;
            padding: $body-padding;

            &.m-portlet__body--no-padding {
                padding: 0;
            }

            &.m-portlet__body--no-top-padding {
                padding-top: 0;
            }

            &.m-portlet__body--no-left-padding {
                padding-left: 0;
            }

            &.m-portlet__body--no-right-padding {
                padding-right: 0;
            }
        }   

        .m-portlet__foot  {
            padding: $footer-padding;

            &.m-portlet__foot--fit {
                padding: 0 !important; 
            } 
        }

        //== Body loding
        .m-portlet__body  {
            .m-portlet__body-progress {
                display: none;
            }
        }

        &.m-portlet--body-progress {
            .m-portlet__body  {
                position: relative;
                animation: k-animate-fade-out 0.3s;

                .m-portlet__body-progress {
                    background-color: #fff;
                    position: absolute; 
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                }
            }

            &.m-portlet--body-progress-overlay {
                .m-portlet__body  {
                    .m-portlet__body-progress {
                        background-color: transparent;
                    }
                }
            }
        }

        //== collapse portlet
        &.m-portlet--collapsed,
        &.m-portlet--collapse {
            .m-portlet__head  { 
                .m-portlet__head-tools  {
                    // portlet nac
                    .m-portlet__nav {
                        .m-portlet__nav-item {
                            .m-portlet__nav-link {
                                > i.la-angle-down {
                                    &:before {
                                        content: "\f113";
                                    }
                                }

                                > i.la-plus {
                                    &:before {
                                        content: "\f28e";
                                    }
                                }  
                            }
                        }
                    }
                }
            }
        }

        //== collapsed portlet
        &.m-portlet--collapsed {
            > .m-form,
            > .m-portlet__body {
                display: none; 
            }
        }

        //== collapse portlet
        &.m-portlet--fullscreen {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            margin: 0; 

            > .m-portlet__head  { 
                .m-portlet__head-tools  {
                    // portlet nac
                    .m-portlet__nav {
                        .m-portlet__nav-item {
                            [data-portlet-tool=toggle] {
                                > i {
                                    @include opacity(0.5);
                                }
                            }

                            .m-portlet__nav-link {
                                > i.la-expand {
                                    &:before {
                                        content: "\f1a4";
                                    }
                                }  
                            }
                        }
                    }
                }
            }

            > .m-form,
            > .m-portlet__body  {
                left: 0;
                right: 0;
                top: $height;
                bottom: 0;
                position: fixed;
                overflow: auto; 
            } 

            > .m-portlet__foot  {
                position: relative;
                top: 100%;
            }

            &.m-portlet--head-sm {
                > .m-portlet__body  {
                    top: $height-sm;   
                }
            }

            &.m-portlet--head-lg {
                > .m-portlet__body  {
                    top: $height-lg;   
                }
            }
        }

        &.m-portlet--fit {
            position: relative;
        }

        &.m-portlet--widget-fit {
            .m-portlet__head {
                position: relative;
                z-index: 1;
            }
        }

        //== Semi bordered mode: remove header bottom border
        &.m-portlet--bordered-semi {
            .m-portlet__body {
                padding-top: $bordered-semi-portlet-body-padding-top;
            }
        }

        //== Portlet space
        &.m-portlet--space {
            padding: 0 $space;

            .m-portlet__head  {  
                padding: $space 0;
            }

            .m-portlet__body  {
                padding: $space 0;
            }   

            .m-portlet__foot  {
                > .m-form__actions {
                    padding: $space 0;
                }
            }
        }

        //== Apply portlet padding to inner element.
        .m-portlet__padding-x  {  
            padding: 0 $space;
        }

        //== Portlet tabs
        &.m-portlet--tabs {
            .m-portlet__head  {
                padding: 0 $space;

                .m-portlet__head-caption {
                    //padding: $space 0;
                }

                .m-portlet__head-tools  {
                    padding: 0;
                    align-items: stretch;

                    // portlet tabs
                    .m-tabs-line {
                        position: relative;
                        margin: 0; 
                        border-bottom-color: transparent;
                        bottom: -1px;
                        display:flex;
                        align-items: stretch;

                        .m-tabs__item {
                            display:flex;
                            align-items: stretch;
                        }

                        .m-tabs__link {
                            display:flex;
                            align-items: center;
                        }

                        &.m-tabs-line--2x {
                            bottom: -3px;
                        }
                    }
                }
            }
        }

        &.m-portlet--last {
            margin-bottom: 0;
        }

        //== Portlet header small size
        &.m-portlet--head-sm {
            .m-portlet__head  {
                height: $height-sm;
            }

            .m-portlet__foot,
            .m-portlet__body  {
                padding-top: $space - 0.5rem;
                padding-bottom: $space - 0.5rem;  
            }
        }

         //== Portlet header large size
        &.m-portlet--head-lg {
            .m-portlet__head  {
                height: $height-lg;
            }
        }

        //== Portlet styles
        &.m-portlet--unair {
            @include shadow(none);
        }

        &.m-portlet--rounded {
            @include border-radius(array-get($m--border-radius, general));

            .m-portlet__head {
                @include border-top-left-radius(array-get($m--border-radius, general));
                @include border-top-right-radius(array-get($m--border-radius, general));
            }

            .m-portlet__body {
                @include border-bottom-left-radius(array-get($m--border-radius, general));
                @include border-bottom-right-radius(array-get($m--border-radius, general));
            }

            .m-portlet__foot {
                @include border-bottom-left-radius(array-get($m--border-radius, general));
                @include border-bottom-right-radius(array-get($m--border-radius, general));
            }
        }

        &.m-portlet--rounded-force {
            overflow: hidden;
        }

        //== Responsive mode
        &.m-portlet--responsive-mobile {
            @include mobile {
                .m-portlet__head  { 
                    height: auto;
                    flex-direction: column;
                    flex-wrap: wrap;

                    .m-portlet__head-wrapper {
                        flex-direction: column;
                        flex-wrap: wrap;
                    }

                    .m-portlet__head-caption  {
                        flex-grow: 1;
                        padding-top: $responsive-head-padding-y;
                        padding-bottom: $responsive-head-padding-y;
                        flex-wrap: wrap;
                    }

                    .m-portlet__head-tools  {
                        flex-grow: 1;
                        padding-top: 0;
                        padding-bottom: $responsive-head-padding-y;
                        flex-wrap: wrap;
                    }
                }
            }
        }

        &.m-portlet--responsive-tablet-and-mobile {
            @include tablet-and-mobile {
                .m-portlet__head  { 
                    height: auto;
                    flex-direction: column;
                    flex-wrap: wrap;

                    .m-portlet__head-wrapper {
                        flex-direction: column;
                        flex-wrap: wrap;
                    }

                    .m-portlet__head-caption  {
                        flex-grow: 1;
                        padding-top: $responsive-head-padding-y;
                        padding-bottom: $responsive-head-padding-y;
                        flex-wrap: wrap;
                    }

                    .m-portlet__head-tools  {
                        flex-grow: 1;
                        padding-top: 0;
                        padding-bottom: $responsive-head-padding-y;
                        flex-wrap: wrap;
                    }
                }
            }
        }

        //== Form layout integrtion        
        .m-form.m-form--fit > .m-portlet__body {
            padding-left: 0px;
            padding-right: 0px;
        }

        //== Sortable mode
        //== jQuery UI Draggable Portlets
        &.m-portlet--sortable {
            .m-portlet__head {
                cursor: move;   
            }  
        }
            
        &.m-portlet--sortable-empty {
            visibility: hidden;
            height: 45px;  
            min-height: 125px; 
        }

        .m-portlet-fit--sides {
            margin-left: - $space;
            margin-right: - $space;
        }

        .m-portlet-fit--bottom {
            margin-bottom: - $space;
        }

        .m-portlet-fit--top {
            margin-top: - ($height + $space);
        }

        &.m-portlet--bordered-semi {
            .m-portlet-fit--top {
                margin-top: - ($height + $bordered-semi-portlet-body-padding-top);
            }
        }

        //== Portlet seperator
        .m-portlet__body-separator {
            height: 0;
            overflow: hidden;
            margin: 30px (- $space); 
        }

        //== Creative Portlet
        &.m-portlet--creative {
            $m-portlet-head-height: 2.75rem;
            $m-portlet-head-padding: 0 1.75rem;
            $m-portlet-label-height: 3.5rem;
            $m-portlet-top-padding: 3.5rem; 
            $m-portlet-top-margin: 3.5rem;

            padding-top: $m-portlet-top-padding;
            margin-top: $m-portlet-top-margin;

            &.m-portlet--first {
                margin-top: $m-portlet-head-height / 2;
            }

            .m-portlet__head  {     
                height: $m-portlet-head-height;

                .m-portlet__head-caption  {
                    position: relative;
                    display: flex;

                    .m-portlet__head-title  {
                        .m-portlet__head-icon  {
                            @include icons-attr(font-size, (fontawesome: 1.2rem, lineawesome: 1.3rem, metronic: 1.4rem) );
                        }

                        .m-portlet__head-text  {
                            font-size: get-font-size(regular);
                            font-weight: get-font-weight(bold);
                            font-family: #{get-regular-font-family()};

                            .m-portlet__head-desc {
                                font-size: get-font-size(regular, '--');
                                font-weight: get-font-weight(normal);
                            } 
                        }                    
                    }

                    .m-portlet__head-label  {
                        position: absolute;
                        top: -($m-portlet-label-height / 2 + $m-portlet-top-padding);
                        left: 0;
                        font-size: get-font-size(regular, '++');
                        font-weight: get-font-weight(bolder);
                        display: table;
                        padding: $m-portlet-head-padding;
                        height: $m-portlet-label-height;

                        > span {
                            vertical-align: middle;
                            display: table-cell;
                        }
                    }
                } 
            }

            .m-portlet__body  { 
                padding-top: 1rem;
            }
        } 

        //== Head overlay
        &.m-portlet--head-overlay {  
            overlay: hidden;

            .m-portlet__head  {
                position: relative;
                border: 0;
                z-index: 2;
            }

            .m-portlet__body  {  
                position: relative;
                z-index: 1;
                margin-top: -($height);
                padding-top: 0;
            }
        }

        //== Sticky portlet head
        &.m-portlet--sticky {
            .m-portlet__head  { 
                position: fixed;
                box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
                z-index: 101;
                background: #fff;
            }
        }        
    }

    @include desktop() {
        .m-portlet  {
            //== full height portlet mode
            &.m-portlet--full-height {
                height: calc(100% - #{$space});

                .m-portlet__body {
                    height: calc(100% - #{$space} - #{$space});
                }
            }

            //== full height portlet mode
            &.m-portlet--half-height {
                height: calc(50% - #{$space});
            }
        }
    }

    //== fullscreen mode
    body.m-portlet--fullscreen {
        overflow: hidden !important;

        &.m-header--fixed {
            .m-header {
                display: none;
            }
        }

        &.m-aside-left--fixed {
            .m-aside-left {
                display: none;
            }
        }        
    }

    // tablet and mobile mode
    @include tablet-and-mobile {
        .m-portlet  {
           .m-portlet__head  {       
                .m-portlet__head-caption  {
                    .m-portlet__head-title  {
                        .m-portlet__head-text  {
                            font-size: get-font-size(regular, '+');

                            small {
                                font-size: get-font-size(regular, '-');
                            }

                            .m-portlet__head-desc {
                                font-size: get-font-size(regular, '-');
                            } 
                        }                    
                    }
                }
            }
        }
    }

    // mobile mode
    @include mobile {
        .m-portlet  {
            //== Portlet tabs
            &.m-portlet--tabs {
                .m-portlet__head  {
                    display: block;
                    height: auto;
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;

                    .m-portlet__head-caption {
                        display: block;
                        float: none;
                    }

                    .m-portlet__head-tools  {
                        display: block; 
                        float: none;
                        margin-top: 1rem;

                        .nav-tabs.m-tabs-line {
                            float: none;

                            > .nav-item.m-tabs__item {
                                margin-right: 0.75rem;

                                > .nav-link.m-tabs__link {
                                    padding: 0.5rem 0;
                                }
                            }
                        } 
                    }
                }

                &.m-portlet--head-solid-bg {
                    .m-portlet__head  {
                        .m-portlet__head-tools  {
                            .nav-tabs.m-tabs-line {
                                > .nav-item.m-tabs__item {
                                    > .nav-link.m-tabs__link {
                                        padding: 0.5rem 1rem;
                                    }
                                }
                            } 
                        }
                    }
                }
            }
        }
    }
}

//== Component Skin
@mixin m-build-component--portlets-skin($skin) {
    @include component-skin(m-portlet, $skin) {
        @include shadow(get-shadow($skin, '--'));  
        background-color: get-color($skin, box);

        .m-portlet__head  {
            .m-portlet__head-icon  {
                color: get-color($skin, icon, '++');
            }

            .m-portlet__head-text  {
                color: get-color($skin, regular);

                .m-portlet__head-desc,
                small {
                    color: get-color($skin, muted);
                }
            }

            .m-portlet__head-tools  {
                .m-portlet__nav {
                    .m-portlet__nav-item {
                        .m-portlet__nav-link {
                            &.m-portlet__nav-link--icon {
                                i {
                                    color: array-get($m--portlet, skins, $skin, tools, nav, icon, color, default);
                                }

                                &:hover {
                                    i {
                                        color: array-get($m--portlet, skins, $skin, tools, nav, icon, color, hover);
                                    }
                                }
                            }
                        }
                    }
                }
            }    

            border-bottom: 1px solid get-color($skin, panel);

            &.m-portlet__head-no-border {
                border-bottom: 0;
            }
        }

        .m-portlet__body {
            color: get-color($skin, regular);
        }

        .m-portlet__foot:not(.m-portlet__no-border)  {
            border-top: 1px solid get-color($skin, panel);

            &.m-portlet__foot--top {
                border-top: 0;
                border-bottom: 1px solid get-color($skin, panel);
            }

            &.m-portlet__foot-no-border {
                border: 0;
            }
        }    

        //== Portlet styles
        // bordered style
        &.m-portlet--bordered {
            border: 1px solid get-color($skin, panel);
        }

        // semi bordered mode: remove header bottom border
        &.m-portlet--bordered-semi {
            .m-portlet__head  {
                border: 0;
            }
        }

        //== Sortable mode
        //== jQuery UI Draggable Portlets
        &.ui-sortable-helper {
            border: 1px dashed get-color($skin, panel);   
        }

        //== Portlet seperator
        .m-portlet__body-separator {
            border-bottom: 1px solid get-color($skin, panel, '-');
        }

        //== Creative Portlet
        &.m-portlet--creative {
            .m-portlet__head  {     
                .m-portlet__head-caption  {
                    .m-portlet__head-label  {
                        @include shadow(get-shadow($skin, '+'));  

                        @each $name, $color in $m--state-colors {
                            &.m-portlet__head-label--#{$name} {
                                background: array-get($color, base);
                                color: array-get($color, inverse);
                            } 
                        }
                    }
                } 
            }
        } 


        //== Angular source code viewer
        //== Angular source code viewer
        &.m-portlet--ngviewer {
            .m-portlet__code {
                border-bottom: 1px solid get-color($skin, panel, '-');
            }
        }
    }
}

//== Component States
@mixin m-build-component--portlets-states() {
    @each $name, $color in $m--state-colors {
        //== Portlet Styles
        .m-portlet.m-portlet--#{$name} {
            &.m-portlet--head-solid-bg {
                .m-portlet__head {
                    background-color: array-get($color, base);

                    .m-portlet__head-icon  {
                        color: darken(array-get($color, inverse), 3%);
                    }

                    .m-portlet__head-text  {
                        color: array-get($color, inverse);

                        small {
                            color: darken(array-get($color, inverse), 6%);
                        }
                    }

                    border-color: array-get($color, base);
                }                

                .m-portlet__head-tools  {
                    // portlet navs
                    .m-portlet__nav {
                        .m-portlet__nav-item {
                            .m-portlet__nav-link {
                                &.m-portlet__nav-link--icon {
                                    i {
                                        color: darken(array-get($color, inverse), 3%);
                                    }

                                    &:hover {
                                        i {
                                            color: array-get($color, inverse);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // portlet outline light button style
                    .btn-outline-light {
                        border-color: rgba(#fff, 0.1); 
                    }

                    // portlet tabs
                    .m-tabs-line {
                        bottom: 0;
                        border: 0;

                        .m-tabs__item {
                            margin: 0 1px 0 0;

                            &:last-child {
                                margin-right: 0;
                            }
                        }

                        .m-tabs__link {
                            padding-left: 20px;
                            padding-right: 20px;
                            color: array-get($color, inverse);
                            border: 0;

                            > i {
                                color: darken(array-get($color, inverse), 6%);
                            }

                            @include opacity(0.8);
                        }

                        .m-tabs__link:hover {
                            border-bottom: 0;
                        }

                        .m-tabs__item.show .m-tabs__link,
                        .m-tabs__link:hover,
                        .m-tabs__link.active {
                            padding-left: 20px;
                            padding-right: 20px;
                            @include border-radius(0);
                            @include opacity(1);
                            background: none;
                            color: array-get($color, inverse);
                            border: 0;
                            background: rgba(array-get($color, inverse), 0.08);
                            
                            > i {
                                color: lighten(array-get($color, inverse), 5%);
                            }
                        } 
                    }
                } 

                .m-portlet__body {
                    background: white;
                }

                .m-portlet__foot {
                    background: white;
                    //border-color: array-get($color, base);
                }

                background: array-get($color, base);
                border-color: array-get($color, base);
            }            
        }

        //== State colors
       .m-portlet.m-portlet--border-bottom-#{$name} {      
            border-bottom: 3px solid array-get($color, base); 
        }
    }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--portlets-base();

//== Component Skin - Light
@include m-build-component--portlets-skin(light);

//== Component Skin - Dark
@include m-build-component--portlets-skin(dark);

//== Component Skin - Light
@include m-build-component--portlets-states();
