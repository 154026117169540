//
//** Bootstrap Notify Component
//

//== Mixins
@mixin m-build-component--toastr-base() {
    .toast {
        background-position: 15px center #{'/*rtl:calc(100% - 15px) center*/'} !important;

    	.toast-close-button {
			@include m-generate-close-icon();
       		
       		outline:none !important;
    	    font-size: 0;
    	    
            &:before {
            	font-size: get-font-size(icon, '--');
            }
    	}
    }
}

@mixin m-build-component--toastr-state($skin, $name, $color) {
	.toast-#{$name} {
	    background-color: array-get($color, base);
	    color: array-get($color, inverse);
	    @include shadow(get-shadow($skin));
	}

    #toast-container > div:hover {
    	@include shadow(get-shadow($skin, '++'));
    }
}

@mixin m-build-component--toastr-skin($skin) {
	@include m-build-component--toastr-state($skin, success, array-get($m--state-colors, success));
	@include m-build-component--toastr-state($skin, warning, array-get($m--state-colors, warning));
	@include m-build-component--toastr-state($skin, info, array-get($m--state-colors, info));
	@include m-build-component--toastr-state($skin, error, array-get($m--state-colors, danger));
}

//== Build
@include m-build-component--toastr-base();

@include m-build-component--toastr-skin(light);