//
//** Mail Config
//

$m--apps-mail: (
	
	//== Aside
	aside: (
		//== Itself
		self: (
			width: 245px,
			bg-color: #ffffff
		),

		//== Toggler
		toggler: (
			width: 26px,
			height: 26px,  
			transition-duration: 0.4s,
			thickness: 2px,
			space: 6px, 
			radius: 2px,
			color: (
				default: #5d5f77,
				hover: get-brand-color(),
				active: get-brand-color()
			)
		),
		
		//== Offcanvas mode
		offcanvas: (
			//== Panel
			zindex: 1001,
			direction: left,
			transition:	all .3s ease,
			width: 225px,

			//== Close button
			close: (
				layout: (
					size: 25px,
					font-size: 1.4rem
				),
				skins: (
					dark: (
						icon: (
							default: #968ad4,
							hover: #ffffff
						),
						bg: (
							default: #323446,
							hover: get-brand-color()
						)
					),
					light: (
						icon: (
							default: lighten(#5e6383, 10%),
							hover: #ffffff
						),
						bg: (
							default: darken(#f8f8fb, 3%),
							hover: get-brand-color()
						)
					)
				)
			)
		),

		//== Menu
		menu: (
			//== Base parameters required to build the base menu
			base:	(
				class: m-mail__menu, // menu element class name
				parent-class: m-mail__aside, // manu parent aside element class name
				mode: general, // menu mode: general or tablet-and-mobile
			),

			//== Customize the menu
			build: (
				//== Layout
				layout: (
					//== Default mode
					default: (
						// menu panel itself
						self: (
							padding: 30px 0 30px 0
						),

						// root item
						item: (
							// item itself
							self: (
								margin:	0
							),

							// item link
							link: (
								// link itself
								self: (
									height: 44px,				
									padding: 0.8rem 3rem
								),					

								// link icon
								icon:	(
									width: 35px,
									align: left,									
									font-size: 1.2rem
								),		

								// link bullet
								bullet:	(
									self: (
										width: 15px,
									),

									dot: (
										size: 4px
									),

									line: (
										width: 5px,
										height: 1px
									)							
								),					

								// link text
								text:	(
									font-size: 1rem,
									font-weight: 500,
									font-transform:	initial
								),

								// link arrow
								arrow:	(
									width: 20px,
									align: right,
									transition: all 0.3s ease,
									font-size: 0.7rem
								),

								// link badge
								badge:	(
									padding: 0px 0px 0px 5px,
									align: right
								)
							),

							// item submenu
							submenu: (
								// submenu self
								self: (
									indent: 15px,
									margin:	0
								),

								// submenu item
								item: (
									// item itself
									self: (
										margin:	0
									),

									// item link
									link: (
										// link itself
										self: (
											height: 40px,		
											padding: 0 30px,
											padding-x: 30px
										),					

										// link icon
										icon:	(
											width: 35px,
											align: left,
											font-size: 1.35rem
										),		

										// link bullet
										bullet:	(
											self: (
												width: 20px,
											),
											dot: (
												size: 4px
											),
											line: (
												width: 5px,
												height: 1px
											)							
										),						

										// link text
										text:	(
											font-size: 1rem,
											font-weight: 400,
											font-transform:	initial
										),

										// link arrow
										arrow:	(
											width: 20px,
											align: right,
											transition: all 0.3s ease,
											font-size: 0.7rem
										),

										// link badge
										badge:	(
											padding: 0px 0px 0px 5px,
											align: right,	
										)
									)
								),

								// submenu section
								section: (
									self: (
										indent: 15px,
										padding: 0 25px,
										margin: 20px 0 0 0,
										height: 40px
									),

									text: (
										font-size: 0.8rem,
										font-weight: 300,
										font-transform: uppercase,
										letter-spacing: 0.3px
									),

									icon: (
										font-size: 1.1rem
									)
								),

								// submenu separator
								separator: (
									margin: 15px 0
								)
							),

							// arrow
							arrow: (
								// classic triangle arrow
								classic: (
									size: 10px, // arrow size
									offset: (  
										arrow: 10px,  // arrow offset
										dropdown: -5px // dropdown offset
									)
								),

								// smooth triangle arrow
								smooth: (
									top: 2px,
									right: -2px,
									margin: 0px -7px 0 0
								) 
							)
						),

						// root section
						section: (
							self: (
								padding: 0 27px,
								margin: 20px 0 0 0,
								height: 40px
							),

							text: (
								font-size: 0.83rem,
								font-weight: 500,
								font-transform: uppercase,
								letter-spacing: 0.3px
							),

							icon: (					
								font-size: 1.1rem
							)
						),

						// root separator
						separator: (
							margin: 15px 0,
						)
					),

					//== Dropdown mode
					dropdown: (
						// dropdown menu item
						item: (
							// item itself
							self: (
								margin: 0, 
								zindex:	100,
							),

							// submenu
							submenu: (
								// submenu itself
								self: (
									// submenu base styles
									padding: 20px 0,
									width: 245px,
									parent-width: 245px,
									border-radius: 4px,

									// submenu animation
									animation: (
										offset: 10px
									),

									// vertical offset
									offset: (
										root: (
											default: -5px,
											up: -10px
										),
										inner: (
											default: -10px,
											up: -10px
										)								
									)
								),

								// submenu item
								item: (
									// item itself
									self: (
										margin:	0
									),

									// item link
									link: (
										// link itself
										self: (
											height: 40px,				
											padding: 7px 30px
										),					
										// link icon
										icon:	(
											width: 35px,
											align: left,
											font-size: 1.35rem
										),		
										// link bullet
										bullet:	(
											self: (
												width: 20px,
											),
											dot: (
												size: 4px
											),
											line: (
												width: 5px,
												height: 1px
											)							
										),						
										// link text
										text:	(
											font-size: 1rem,
											font-weight: 400,
											font-transform:	initial
										),

										// link arrow
										arrow:	(
											width: 20px,
											align: right,
											transition: all 0.3s ease,
											font-size: 0.7rem
										),

										// link badge
										badge:	(
											padding: 0px 0px 0px 5px,
											align: right,	
										)
									)
								),

								// submenu section
								section: (
									self: (
										margin: 20px 0 0 0,
										padding: 0 25px,
										height: 40px
									),
										
									text: (
										font-size: 0.8rem,
										font-weight: 300,
										font-transform: uppercase,
									),

									icon: (
										font-size: 1.1rem,
									)
								),

								// submenu separator
								separator: (
									margin: 15px 0,
								),

								// submenu arrow
								arrow: (
									// classic triangle arrow
									classic: (
										offset: (
											default: 12px,
											up: 20px
										),
										size: 10px,
										left: 0,
										margin: 0
									),

									// smooth triangle arrow
									smooth: (
										offset: (
											default: 10px,
											up: 20px
										),
										left: 1px,
										margin: 0
									) 
								)
							)
						)
					)		
				),

				//== Skins
				skins: (
					//== Light skin
					light: (
						//== Default mode
						default: (
							// menu panel itself
							self: (
								bg-color: #ffffff
							),

							// root item
							item: (
								// item itself
								self: (
									bg-color: (
										default: null,
										open: null,
										expanded: null,
										active: null,
										hover: null
									)
								),

								// item link
								link: (
									// link itself
									self: (
										bg-color: (
											default: null,
											open: #f8f8fb,
											expanded: #f8f8fb,
											active: #f8f8fb,
											hover: #f8f8fb
										)
									),					

									// link icon
									icon: (
										font-color:	(
											default: lighten(#8c8ea4, 20%),
											open: lighten(#8c8ea4, 5%),
											expanded: lighten(#8c8ea4, 5%),
											active: get-brand-color(), 
											hover: lighten(#8c8ea4, 15%)
										),
									),		

									// link bullet
									bullet:	(
										dot: (
											bg-color: (
												default: lighten(#8c8ea4, 10%),
												open: lighten(#8c8ea4, 5%),
												expanded: lighten(#8c8ea4, 5%),
												active: get-brand-color(),
												hover: lighten(#8c8ea4, 5%)
											)
										),
										line: (
											bg-color: (
												default: lighten(#8c8ea4, 10%),
												open: lighten(#8c8ea4, 5%),
												expanded: lighten(#8c8ea4, 5%),
												active: get-brand-color(),
												hover: lighten(#8c8ea4, 5%)					
											)
										)							
									),					

									// link text
									text:	(
										font-color:	(
											default: darken(#868aa8, 2%),
											open: darken(#868aa8, 3%),
											expanded: darken(#868aa8, 20%),
											active: get-brand-color(),
											hover: darken(#868aa8, 20%)	
										)	
									),

									// link arrow
									arrow:	(
										font-color:	(
											default: lighten(#8c8ea4, 10%),
											open: lighten(#8c8ea4, 5%),
											expanded: lighten(#8c8ea4, 5%),
											active: get-brand-color(),
											hover: lighten(#8c8ea4, 5%)
										)
									)
								),

								// item submenu
								submenu: (
									// submenu self
									self: (
										bg-color: (
											default: null,
											open: null,
											active: null,
											hover: null
										)
									),

									// submenu item
									item: (
										// item itself
										self: (
											bg-color: (
												default: null,
												open: null,
												expanded: null,
												active: null,
												hover: null
											)
										),

										// item link
										link: (
											// link itself
											self: (
												bg-color: (
													default: null,
													open: #f8f8fb,
													expanded: #f8f8fb,
													active: #f8f8fb,
													hover: #f8f8fb
												)
											),					

											// link icon
											icon:	(
												font-color:	(
													default: lighten(#8c8ea4, 10%),
													open: lighten(#8c8ea4, 5%),
													active: get-brand-color(), 
													hover: lighten(#8c8ea4, 5%)
												)
											),		

											// link bullet
											bullet:	(
												dot: (
													bg-color: (
														default: lighten(#8c8ea4, 10%),
														open: lighten(#8c8ea4, 5%),
														expanded: lighten(#8c8ea4, 5%),
														active: get-brand-color(),
														hover: lighten(#8c8ea4, 5%)
													)
												),

												line: (
													bg-color: (
														default: lighten(#8c8ea4, 10%),
														open: lighten(#8c8ea4, 5%),
														expanded: lighten(#8c8ea4, 5%),
														active: get-brand-color(),
														hover: lighten(#8c8ea4, 5%)
													)
												)							
											),						

											// link text
											text: (
												font-color:	(
													default: darken(#868aa8, 5%),
													open: darken(#868aa8, 10%),
													active: get-brand-color(),
													hover: darken(#868aa8, 10%)
												)	
											),

											// link arrow
											arrow:	(
												font-color:	(
													default: lighten(#8c8ea4, 10%),
													open: lighten(#8c8ea4, 5%),
													active: get-brand-color(),
													hover: lighten(#8c8ea4, 5%)
												)
											)
										)
									),

									// submenu section
									section: (
										text: (
											font-color: lighten(#868aa8, 10%)
										),
										icon: (
											font-color: lighten(#868aa8, 12%)
										)
									),

									// submenu separator
									separator: (
										border: 1px solid rgba(#000000, 0.07)
									)
								)
							),

							// root section
							section: (
								text: (
									font-color: lighten(#868aa8, 10%)
								),
								icon: (
									font-color: lighten(#868aa8, 12%)
								)
							),

							// root separator
							separator: (
								border: 1px solid rgba(#000000, 0.07)
							)
						),

						//== Dropdown mode
						dropdown: (
							// dropdown menu item
							item: (
								// submenu
								submenu: (
									// submenu itself
									self: (
										// submenu base styles
										bg-color: #fff,
										box-shadow: get-shadow(dark),
									),
									
									// submenu item
									item: (
										// item itself
										self: (
											bg-color: (
												default: null,
												open: null,
												expanded: null,
												active: null,
												hover: null
											)
										),

										// item link
										link: (
											// link itself
											self: (
												bg-color: (
													default: null,
													open: #f8f8fb,
													expanded: #f8f8fb,
													active: #f8f8fb,
													hover: #f8f8fb
												)
											),					

											// link icon
											icon:	(
												font-color:	(
													default: lighten(#8c8ea4, 10%),
													open: lighten(#8c8ea4, 5%),
													active: get-brand-color(), 
													hover: lighten(#8c8ea4, 5%)
												)
											),		

											// link bullet
											bullet:	(
												dot: (
													bg-color: (
														default: lighten(#8c8ea4, 10%),
														open: lighten(#8c8ea4, 5%),
														expanded: lighten(#8c8ea4, 5%),
														active: get-brand-color(),
														hover: lighten(#8c8ea4, 5%)
													)
												),

												line: (
													bg-color: (
														default: lighten(#8c8ea4, 10%),
														open: lighten(#8c8ea4, 5%),
														expanded: lighten(#8c8ea4, 5%),
														active: get-brand-color(),
														hover: lighten(#8c8ea4, 5%)
													)
												)							
											),						

											// link text
											text: (
												font-color:	(
													default: darken(#868aa8, 10%),
													open: darken(#868aa8, 15%),
													active: get-brand-color(),
													hover: darken(#868aa8, 15%)
												)	
											),

											// link arrow
											arrow:	(
												font-color:	(
													default: lighten(#8c8ea4, 10%),
													open: lighten(#8c8ea4, 5%),
													active: get-brand-color(),
													hover: lighten(#8c8ea4, 5%)
												)
											)
										)
									),

									// submenu section
									section: (
										text: (
											font-color: lighten(#868aa8, 10%)
										),
										icon: (
											font-color: lighten(#868aa8, 12%)
										)
									),

									// submenu separator
									separator: (
										border: 1px solid rgba(#000000, 0.07)
									)
								)
							)		
						)	
					)
				)			
			)
		)		
	),	

	//== List
	list: (
		//== Itself
		self: (
			width: 36%,
			bg-color: #ffffff
		)
	),

	//== Item
	item: (
		//== Itself
		self: (	
			width: 64%,		 
			bg-color: #ffffff
		)
	)
);