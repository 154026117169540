//
//** Footer Settings
//

$m-config-footer: (			
	default: (
		self: (
			padding: (
				desktop: 7px 30px,
				mobile: 7px 20px
			),
			height: 60px,
			bg-color: #ffffff,
			shadow: -2px -8px 41px -14px rgba(202,210,222,0.61)
		),
		copyright: (
			color: #a9a9aa,
			font-size: 0.9rem,
			font-weight: 400
		)				
	),
	fixed: (
		zindex: 102
	)	
);